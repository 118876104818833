import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy'
import { useLoadable } from '@/packages/vue-loading-state'
import { BaseResource } from '@/plugins/resource'


export function useCancelableRequest<T>(
  resource: BaseResource
) {

  function request(...args) {
    const cancelController = new AbortController()
    const _resource = resource.config(
      'signal',
      cancelController.signal
    )
    const _request = cancellablePromiseProxy(
      new Promise((resolve, reject) => {
        _resource.execute(...args).then(resolve, reject)
      }),
      { cancelController }
    )
      .then((data ) => {
        return data || null
      })
      .catch((err) => (err.name == 'AbortError' ? [] : err))
    return _request
  }
  const { load, loading, result } = useLoadable(request)

  return { load, loading, result, request }
}


export default {}