import { i18n } from '@/i18n'

const { t } = i18n.global

export enum ProfileTypeChoices {
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4'
}


export const GET_PROFILE_TYPES = t => ([
  {
    value: ProfileTypeChoices.B3,
    label: t('Medical institution'),
    menuTitle: t('Medical facility data'),
    facilityAddTitle: t('Add medical facility'),
    facilityAddBtn: t('Add medical facility'),
    facilityAddText: t('To start using the service, you need to add a medical facility.'),
  },
  {
    value: ProfileTypeChoices.B2,
    label: t('Doctor with a private practice (I will add the medical facility myself)'),
    menuTitle: t('Medical facility data'),
    facilityAddTitle: t('Add medical facility'),
    facilityAddBtn: t('Add medical facility'),
    facilityAddText: t('To start using the service, you need to add a medical facility.'),
  },
  {
    value: ProfileTypeChoices.B1,
    label: t('A doctor of a medical institution that is already represented on the platform'),
  },
  {
    value: ProfileTypeChoices.B4,
    label: t('Branch of a medical institution'),
    menuTitle: t('Branch'),
    facilityAddTitle: t('Add branch'),
    facilityAddBtn: t('Add branch'),
    facilityAddText: t('To start using the service, you need to add a  branch of medical facility.'),
  },
])

export type NotificationFlag = {
  id: number
  label: string
  display: string
}

export interface Notification {
  id: string
  flags: NotificationFlag[]
  data: {
    icon: string
    body: string
    title: string
  }
}

export interface NotificationGroup {
  date: string
  notifications: Notification[];
}


export enum NOTIFICATIONS_FLAGS {
  UNREAD = 73232,
  READ = 73236,
  BALANCE = 10,
  APPOINTMENT = 11,
  CORRESPONDENCE = 12,
  REPLIES = 13,
}

export const NotificationsTypesTitles = {
  [NOTIFICATIONS_FLAGS.BALANCE]: t('Balance'),
  [NOTIFICATIONS_FLAGS.APPOINTMENT]: t('Appointment'),
  [NOTIFICATIONS_FLAGS.CORRESPONDENCE]: t('Correspondence'),
  [NOTIFICATIONS_FLAGS.REPLIES]: t('Replies'),
}

export const NotificationsTypes = [
  {
    id: NOTIFICATIONS_FLAGS.BALANCE,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.BALANCE],
  },
  {
    id: NOTIFICATIONS_FLAGS.APPOINTMENT,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.APPOINTMENT],
  },
  {
    id: NOTIFICATIONS_FLAGS.CORRESPONDENCE,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.CORRESPONDENCE],
  },
  {
    id: NOTIFICATIONS_FLAGS.REPLIES,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.REPLIES],
  },
]
