
import { App, defineAsyncComponent } from 'vue'

const Cancel = defineAsyncComponent(() => import('./Cancel.vue'))

const install = (app: App<Element>, prefix = 'Appointment'): void => {
  app
    .component(`${prefix}Cancel`, Cancel)
}

export default {
  install,
}
