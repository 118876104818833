import {
  r,
  createSender,
} from '@/plugins/resource/resource'
import {
  queryPostfix,
} from '@/plugins/resource/api'

const MODEL = 'notifications'
const NOTIFICATIONS_MODEL = MODEL + '/notifications'

export const notificationsFlagsResource = r('/flags/list/', MODEL)

export const notificationsListResource = r(queryPostfix('/list/', ['flags']), NOTIFICATIONS_MODEL)
export const notificationsStatsResource = r(
  '/stats/{?actor_types,action_types,target_types}',
  NOTIFICATIONS_MODEL
)

export const notificationsClearResource = r(
  '/clear/',
  NOTIFICATIONS_MODEL,
  createSender
)
export const notificationsChangeFlagsResource = r(
  '/change-flags/',
  NOTIFICATIONS_MODEL,
  createSender
)
export const notificationsSingleChangeFlagsResource = r(
  '/{id}/change-flags/',
  NOTIFICATIONS_MODEL,
  createSender
)
